<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import MobileAppImage from '@/images/landing-page/webflow/mobile-app.jpg'
  import CelImage from '@/images/icon/cel.svg'
  import ToolImage from '@/images/icon/tools.svg'
  import SearchImage from '@/images/icon/search.svg'
  import CarsImage from '@/images/icon/cars.svg'

  const items = [
    {
      uri: CelImage,
      title: 'Engine light -',
      description: 'Check engine light notifications straight to your phone.',
    },
    {
      uri: SearchImage,
      title: 'Problem Details -',
      description: 'Understand the issue and the consequence if ignored.',
    },
    {
      uri: ToolImage,
      title: 'Maintenance Reminders -',
      description: 'When your car needs a maintenance check up, you’ll know!',
    },
    {
      uri: CarsImage,
      title: 'Multi-Vehicle Control -',
      description:
        'Link multiple cars to your FIXD app and keep your family safe.',
    },
  ]
</script>

<div class="py-[5%]">
  <Section center="true" verticalPadding="none">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto">
      <div class="xs:flex flex-row text-left items-center justify-center">
        <div class="basis-1/2">
          <div class="flex justify-center">
            <img src={MobileAppImage} alt="free fixd mobile app" />
          </div>
        </div>
        <div class="flex-1 mt-2.5 sm:mt-0 basis-1/2 items-center md:pl-5">
          <div>
            <p class="uppercase text-[#857550]">the free fixd mobile app</p>
            <p class="text-2xl font-bold leading-8 my-2.5">
              Understanding your car just got a whole lot simpler.
            </p>
          </div>

          <div class="flex-col md:flex-row flex flex-wrap">
            {#each items as item}
              <div class="flex basis-1/2 mb-7 md:px-2.5">
                <img
                  src={item.uri}
                  alt={item.title}
                  lazy
                  class="w-16 md:w-11"
                />
                <div class="text-15 md:ml-2.5">
                  <p class="font-semibold">{item.title}</p>
                  <p>{item.description}</p>
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </Section>
</div>
