<script>
  import { onMount } from 'svelte'

  import PaypalButton from '@/components/PaypalButton.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import api from '@/api'
  import Money from '@/util/money.mjs'
  import { clickOrEnterHandler } from '@/util/svelte.js'
  import { encodeURIParams } from '@/util/methods.js'

  import SingleSensorImg from '@/images/landing-page/buybox/Sensor_Single.jpg?w=300'
  import DoubleSensorImg from '@/images/landing-page/buybox/Sensor_Double.jpg?w=300'
  import TripleSensorImg from '@/images/landing-page/buybox/Sensor_Triple.jpg?w=300'
  import gallery1Img from '@/images/landing-page/buybox/1-gallery.jpg?w=300'
  import gallery2Img from '@/images/landing-page/buybox/2-gallery.jpg?w=300'
  import gallery3Img from '@/images/landing-page/buybox/3-gallery.jpg?w=300'
  import gallery4Img from '@/images/landing-page/buybox/4-gallery.jpg?w=300'
  import gallery5Img from '@/images/landing-page/buybox/5-gallery.jpg?w=300'
  import gallery6Img from '@/images/landing-page/buybox/6-gallery.jpg?w=300'
  import Tracker from '@/util/tracker.js'
  import { CheckmarkFilled, StarFilledSharp } from '@/components/icons'
  import Skeleton from '@/components/designs/Skeleton.svelte'

  import ccIconsUrl from '@/images/landing-page/CreditCardIcons.png?height=105'

  const images = [
    { url: SingleSensorImg, altText: 'Single Sensor' },
    { url: gallery1Img },
    { url: gallery2Img },
    {
      url: gallery3Img,
      altText: `A Driver's Best Friend.
FIXD helps you save $1,000s in car repair by diagnostic 7,000+ car
problems from your smartphone.

Translates Engine Lights into Simple, Understandable Terms.
Get Maintenance Remainders.
Monitor Multiple Vehicles.
Easy Wireless Syncing.
Compatible with both Android and iOS devices.
`,
    },
    {
      url: gallery4Img,
      altText: `Engine Light Diagnosis
Shows why it's on in plain English and allows you to clear the light.
Severity: FIXD gives you the severity and consequences of each check
engine light so you know how quickly you need to get it taken care of.
Clear the Light: FIXD allows you to clear any check engine light, so if
it's just a loose gas cap or if you fix it yourself you don't need to go
to a repair shop.`,
    },
    {
      url: gallery5Img,
      altText: `Maintenance Reminders:
Get automatic alerts for all maintenance and oil changes.
Reminders: FIXD gives you automatic alerts of scheduled maintenance and oil
changes for  your exact vehicle. Forgetting scheduled maintenance can cause
thousands in costly car repairs.
Cost Estimate: FIXD gives you a cost estimate for each maintenance interval
based on your car and the parts and labor rates in your area.`,
    },
    {
      url: gallery6Img,
      altText: `Multi-Vehicle Control:
Link multiple vehicles together to monitor your family of vehicles.
All your cars: FIXD lets you see as many vehicles as you want, all from
one account.
Anywhere: FIXD lets you view your family's vehicles anywhere, anytime, right from
your smartphone.`,
    },
  ]

  export let funnel
  export let gateway

  let selectedQuantity = getQuantity(funnel.default_initial_offer_slug)
  let selectedPack = getPack(funnel.default_initial_offer_slug)

  $: selectedOffer =
    updateSelection(selectedQuantity, selectedPack) ??
    funnel.initial_offers.find(
      (o) => o.slug === funnel.default_initial_offer_slug,
    )
  $: selectedOfferSlug =
    selectedOffer?.slug ?? funnel.default_initial_offer_slug

  $: allowedQuantities = [
    ...new Set(funnel.initial_offers.map((o) => getQuantity(o.slug))),
  ].sort()
  $: allowedPacks = new Set(funnel.initial_offers.map((o) => getPack(o.slug)))

  $: selectedOfferStrikethroughPrice = selectedOffer.line_items.find(
    (offer) => offer.product.sku === '1001',
  ).strikethrough_price

  $: enabledExpressCheckouts =
    gateway.name === 'braintree'
      ? new Set(gateway.express_checkouts_enabled)
      : new Set()

  $: warrantyPrice =
    allowedPacks.has('w') &&
    new Money(
      funnel.initial_offers
        .find((o) => getPack(o.slug) === 'w')
        .line_items.find((li) => li.product.sku === 'LW01').price,
    )

  $: loadingPaypalButton = true

  function updateSelection(selectedQuantity, selectedPack) {
    return funnel.initial_offers.find((o) => {
      return (
        getQuantity(o.slug) === selectedQuantity &&
        getPack(o.slug) === selectedPack
      )
    })
  }

  // TODO: this could be a bit cleaner, it relies on slug naming conventions
  function getQuantity(offerSlug) {
    return offerSlug?.slice(0, 3)
  }

  function getPack(offerSlug) {
    return offerSlug?.substr(-1) === 'w' ? 'w' : ''
  }

  function generateCheckoutURL(selectedOfferSlug) {
    return api.checkouts.new.path({
      funnel_slug: funnel.slug,
      offer_slug: selectedOfferSlug,
    })
  }

  $: checkoutURL = generateCheckoutURL(selectedOfferSlug)

  onMount(() => {
    document.addEventListener('upsellery_analytics_updated', () => {
      checkoutURL = generateCheckoutURL(selectedOfferSlug)
    })
  })

  async function redirectToCheckoutUrl() {
    await Tracker.track('LP order now clicked')
    window.location.href = checkoutURL
  }

  let device_data = null

  function onPayPalLoad(ev) {
    ;({ device_data } = ev.detail)

    Tracker.track('LP Paypal', {
      variant: 'loaded',
    })
  }

  const onPayPalClick = () => {
    Tracker.track('LP Paypal', {
      variant: 'clicked',
    })
  }

  function onPaypalSubmit(ev) {
    const { payload } = ev.detail

    const params = {
      device_data: JSON.stringify(device_data),
      paypal: JSON.stringify(payload),
    }
    Tracker.track('LP Paypal', {
      variant: 'submitted',
    })
    window.location.href = `${checkoutURL}?${encodeURIParams(params)}`
  }

  const changePackCount = (quantity) => {
    selectedQuantity = quantity
    switch (quantity) {
      case '1pk':
        images[0] = { url: SingleSensorImg }

        break
      case '2pk':
        images[0] = { url: DoubleSensorImg }

        break
      case '3pk':
        images[0] = { url: TripleSensorImg }

        break

      default:
        images[0] = { url: SingleSensorImg }

        break
    }
  }
</script>

<style>
  .buy-box {
    box-shadow: 1px 2px 3px rgb(0 0 0 / 50%);
  }

  .packs button {
    border: 2px solid var(--fixd-green);
    color: var(--fixd-green);
    font-size: var(--font-size-small);
    background-color: transparent;
    flex: 1;
    padding: 0;
    border-radius: 24px;
    height: 36px;
  }

  @media only screen and (width >= 768px) {
    .packs button {
      border: 2px solid var(--mid-grey);
      color: var(--mid-grey);
      font-size: var(--font-size-xsmall);
      background-color: transparent;
      flex: 1;
      padding: 0.5em 0;
      border-radius: 4px;
    }
  }

  .packs button.selected {
    background-color: var(--fixd-green);
    border: 2px solid var(--fixd-green);
    color: var(--primary-bg-color);
  }
</style>

<div
  class="buy-box rounded-2xl text-left font-inter md:font-montserrat py-5 bg-white"
>
  <div class="md:hidden px-5">
    <div class="flex justify-between items-center">
      <h3 class="text-22">FIXD Sensor</h3>
      <div class="flex items-center">
        <div class="flex gap-1">
          {#each new Array(5) as _}
            <StarFilledSharp color="yellow" size="sm" />
          {/each}
        </div>
        <div class="ml-1">
          <p class="font-bold text-grey">12K</p>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <p class="text-lg leading-5">
        OBD2 Professional Bluetooth Scan Tool & Code Reader
      </p>
    </div>
  </div>

  <div
    class="md:flex flex-row flex-wrap items-center md:items-stretch pl-5 pr-3 md:pr-5"
  >
    <div class="basis-1/2 flex items-center">
      <div class="gallery w-3/5 md:w-full relative h-inherit md:mr-0">
        <Carousel showPagination={false} showArrows={true}>
          {#each images as item}
            <CarouselItem>
              <div class="mx-auto w-4/5">
                <img src={item.url} alt={item.altText} />
              </div>
            </CarouselItem>
          {/each}
        </Carousel>
      </div>
      <div class="my-2 block md:hidden">
        <div>
          <div class="flex flex-wrap items-center leading-6 mb-1">
            <span class="text-2xl text-green font-semibold mr-1"
              >{new Money(selectedOffer.subtotal_price).toString()}</span
            >
            <span class="strikethrough text-red md:hidden"
              >reg ${selectedOfferStrikethroughPrice.amount}</span
            >
          </div>
          <p class="text-sm italic block md:inline-block md:text-base -mt-1">
            * Limit one per household
          </p>
        </div>

        <div class="hidden md:block">
          {#each new Array(5) as _}
            <StarFilledSharp color="yellow" size="sm" />
          {/each}
        </div>
        <div class="font-normal md:font-semibold text-sm my-1">
          <div class="flex items-center">
            <div class="mr-2 shrink-0">
              <CheckmarkFilled color="green" size="sm" />
            </div>
            <p class="leading-4">Free Shipping</p>
          </div>
          <div class="flex items-center md:hidden">
            <div class="mr-2 shrink-0 mb-1">
              <CheckmarkFilled color="green" size="sm" />
            </div>
            <p class="leading-4">Free 1-Year Warranty</p>
          </div>
          <div class="flex items-center">
            <div class="mr-2 shrink-0">
              <CheckmarkFilled color="green" size="sm" />
            </div>
            <p class="leading-4">100% Risk Free Money Back Guarantee</p>
          </div>
        </div>
        <img
          class="h-[35px] hidden md:block"
          src={ccIconsUrl}
          alt="We accept Visa, MasterCard, American Express, and Paypal"
        />
      </div>
    </div>

    <div class="basis-1/2">
      <div class="description hidden md:block">
        <div class="flex md:block ml-3 md:ml-0">
          <h3 class="text-22 hidden md:block">
            FIXD OBD2 Professional Bluetooth Scan Tool & Code Reader for iPhone
            and Android
          </h3>
          <div class="my-2">
            <div>
              <div class="flex flex-wrap items-center leading-6 mb-1">
                <span class="text-2xl text-green font-semibold mr-1"
                  >{new Money(selectedOffer.subtotal_price).toString()}</span
                >
                <span class="strikethrough text-red md:hidden"
                  >reg ${selectedOfferStrikethroughPrice.amount}</span
                >
              </div>
              <p
                class="text-sm italic block md:inline-block md:text-base -mt-1"
              >
                * Limit one per household
              </p>
            </div>

            <div class="hidden md:block">
              {#each new Array(5) as _}
                <StarFilledSharp color="yellow" size="sm" />
              {/each}
            </div>
            <div class="font-normal md:font-semibold text-sm my-1">
              <div class="flex items-center">
                <div class="mr-2 shrink-0">
                  <CheckmarkFilled color="green" size="sm" />
                </div>
                <p class="leading-4">Free Shipping</p>
              </div>
              <div class="flex items-center md:hidden">
                <div class="mr-2 shrink-0 mb-1">
                  <CheckmarkFilled color="green" size="sm" />
                </div>
                <p class="leading-4">Free 1-Year Warranty</p>
              </div>
              <div class="flex items-center">
                <div class="mr-2 shrink-0">
                  <CheckmarkFilled color="green" size="sm" />
                </div>
                <p class="leading-4">100% Risk Free Money Back Guarantee</p>
              </div>
            </div>
            <img
              class="h-[35px] hidden md:block"
              src={ccIconsUrl}
              alt="We accept Visa, MasterCard, American Express, and Paypal"
            />
          </div>
        </div>
      </div>

      <div class="w-full mt-3 md:px-0 md:mt-0 pl-4 pr-6">
        <div>
          <div class="packs flex flex-row flex-wrap gap-2">
            {#each allowedQuantities as quantity}
              <button
                class="button-{quantity} text-lg custom-button"
                class:selected={selectedQuantity === quantity}
                on:click={() => changePackCount(quantity)}
              >
                {quantity.slice(0, 1)} Pack
              </button>
            {/each}
          </div>
          {#if allowedPacks.has('w')}
            <div class="warranty hidden md:block">
              <div class="my-2">
                <input
                  type="radio"
                  name="1-year-warranty"
                  aria-labelledby="1-year-warranty-label"
                  bind:group={selectedPack}
                  value=""
                />
                <label
                  class="text-sm"
                  for="1-year-warranty"
                  id="1-year-warranty-label"
                  use:clickOrEnterHandler={() => (selectedPack = '')}
                >
                  1 Year Warranty
                  <span class="text-green text-base">(Free!)</span>
                </label>
              </div>
              <div class="mb-2">
                <input
                  type="radio"
                  name="lifetime-warranty"
                  aria-labelledby="lifetime-warranty-label"
                  bind:group={selectedPack}
                  value="w"
                />
                <label
                  for="1-year-warranty"
                  id="lifetime-warranty-label"
                  class="text-sm"
                  use:clickOrEnterHandler={() => (selectedPack = 'w')}
                >
                  Lifetime Warranty
                  <span class="text-green text-base"
                    >({warrantyPrice.toString()})</span
                  >
                </label>
              </div>
            </div>
          {/if}
        </div>

        <div class="order-button">
          <div class="my-3">
            <div class="w-full">
              <button
                class="rounded-button"
                on:click={() => redirectToCheckoutUrl()}
              >
                Order Now
              </button>
            </div>
          </div>

          {#if enabledExpressCheckouts.has('paypal')}
            <div class="relative min-h-[50px]">
              <div
                class="absolute z-20 top-0 left-0 h-[50px] w-full"
                class:hidden={!loadingPaypalButton}
              >
                <Skeleton height="50" shape="pill" />
              </div>

              <PaypalButton
                shape="pill"
                bind:loading={loadingPaypalButton}
                height={50}
                extraClasses="min-h-[50px]"
                tokenizationKey={gateway.tokenization_key}
                on:click={onPayPalClick}
                on:load={onPayPalLoad}
                on:submit={onPaypalSubmit}
                on:mockCheckout={() => console.log('Mock PayPal checkout.')}
              />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
