<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Video from '@/images/landing-page/webflow/understand-your-car.mp4'

  $: video = null
  $: videoPaused = false

  const toggleVideo = (video) => {
    const playing = isVideoPlaying(video)

    playing ? video.pause() : video.play()
  }

  const isVideoPlaying = (video) => {
    return !!(
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    )
  }
</script>

<style>
  .play-button {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #fff;
    border-bottom: 15px solid transparent;
  }
</style>

<div class="py-[5%]">
  <Section center="true" verticalPadding="none">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto">
      <div class="xs:flex flex-row text-left items-center">
        <div class="basis-1/2">
          <div class="flex justify-center">
            <div class="relative">
              <video
                bind:this={video}
                bind:paused={videoPaused}
                src={Video}
                alt="understand your car"
                autoplay
                loop
                muted
                type="video/mp4"
                class="max-w-[300px] sm:max-w-[330px]"
              />
              <div class="absolute bottom-4 right-4">
                <div
                  role="button"
                  tabindex={-1}
                  class="rounded-full bg-black h-11 w-11 flex items-center justify-center"
                  on:click={() => {
                    toggleVideo(video)
                  }}
                  on:keypress={() => {
                    toggleVideo(video)
                  }}
                >
                  {#if videoPaused}
                    <div class="ml-1.5 play-button" />
                  {:else}
                    <div
                      class="flex gap-1.5 h-full w-full items-center justify-center"
                    >
                      <div class="h-3/5 w-2 bg-white rounded-sm" />
                      <div class="h-3/5 w-2 bg-white rounded-sm" />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 mt-2.5 sm:mt-0 basis-1/2 items-center">
          <div>
            <p class="uppercase text-[#857550]">understand your car</p>
            <p class="text-30 leading-8 my-2.5">
              Diagnose 7,000+ Car Issues From Your Phone
            </p>
          </div>

          <div class="mb-6">
            <p class="text-15">
              FIXD is like having a mechanic in your pocket at all times. With
              one tap, you’ll instantly know what’s wrong with your car, how bad
              it is (minor or major), if you should keep driving or not, and
              exactly how much it should cost to fix – all in easy-to-understand
              language.
            </p>
          </div>
          <p class="font-semibold text-15">
            ‍Now understanding your car’s health is simple!
          </p>
        </div>
      </div>
    </div>
  </Section>
</div>
