<script>
  import {
    supportURL,
    privacyPolicyURL,
    returnPolicyURL,
    termsOfServiceURL,
  } from '@/util/links'

  const links = [
    {
      text: 'Support',
      url: supportURL,
    },
    {
      text: 'Returns',
      url: returnPolicyURL,
    },
    {
      text: 'Privacy',
      url: privacyPolicyURL,
    },
    {
      text: 'Terms',
      url: termsOfServiceURL,
    },
  ]
</script>

<div class="bg-black pt-[70px] pb-[140px]">
  <div class="flex justify-center">
    {#each links as link}
      <div class="mx-5">
        <a
          href={link.url}
          target="_blank"
          rel="noreferrer"
          class="text-15 text-white underline">{link.text}</a
        >
      </div>
    {/each}
    <div></div>
  </div>
</div>
