<script>
  import mainImage from '@/images/revolutionizing-car-care.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  function jumpToBuyBox() {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<Section centered={true} verticalPadding="short">
  <div class="mb-5 text-center py-[5%]">
    <img
      src={mainImage}
      alt="We're on a mission to revolutionize car care"
      class="w-[188px] h-auto"
    />
  </div>

  <div class="text-center">
    <div class="mb-2">
      <h1 class="font-extrabold text-30 mb-2">Revolutionizing car care</h1>
      <p class="text-15">
        FIXD was designed in Atlanta by 3 Georgia Tech grads on a mission to
        make car care simple, easy, and affordable for everyone. Ready to feel
        confident and in control of car repairs? Order FIXD today risk-free and
        save 67%!
      </p>
    </div>
    <button
      class="rounded-button small-button"
      on:click={() => jumpToBuyBox()}
      on:keypress={() => jumpToBuyBox()}
    >
      Get FIXD Now
    </button>
  </div>
</Section>
