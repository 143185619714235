<script>
  import Image1 from '@/images/landing-page/scorpio/premium-clarity/cell-phone-1.png'
  import Image2 from '@/images/landing-page/scorpio/premium-clarity/cell-phone-2.png'
  import Image3 from '@/images/landing-page/scorpio/premium-clarity/cell-phone-3.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<Section center="true" maxWidth="xl">
  <div class="hidden md:flex mb-7">
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={Image1} alt="Save $1000s" loading="lazy" class="shadow-lg" />

      <div class="text-center leading-tight mt-6">
        <p class="font-semibold text-xl mb-3">Save $1000s At The Mechanic</p>
        <p>
          Feel confident you're not getting ripped off or do repairs yourself
          with our DIY tutorials!
        </p>
      </div>
    </div>
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={Image2} alt="Free Shipping" loading="lazy" class="shadow-lg" />

      <div class="text-center leading-tight mt-6">
        <p class="font-semibold text-xl mb-3">Easily Diagnose Car Problems</p>
        <p>
          FIXD tells you exactly what your check engine light means in simple,
          easy-to-understand terms.
        </p>
      </div>
    </div>
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={Image3} alt="Free Shipping" loading="lazy" class="shadow-lg" />

      <div class="text-center leading-tight mt-6">
        <p class="font-semibold text-xl mb-3">Know Your Repair Costs</p>
        <p>
          See your most likely repair and how much it should cost before going
          to the repair shop.
        </p>
      </div>
    </div>
  </div>

  <div class="flex md:hidden flex-col mb-7 gap-6">
    <div class="text-center">
      <img src={Image2} alt="Free Shipping" loading="lazy" class="shadow-lg" />

      <div class="text-center leading-tight mt-6">
        <p class="font-semibold text-xl mb-3">Easily Diagnose Car Problems</p>
        <p>
          FIXD tells you exactly what your check engine light means in simple,
          easy-to-understand terms.
        </p>
      </div>
    </div>
    <div class="text-center">
      <img src={Image3} alt="Free Shipping" loading="lazy" class="shadow-lg" />

      <div class="text-center leading-tight mt-6">
        <p class="font-semibold text-xl mb-3">Know Your Repair Costs</p>
        <p>
          See your most likely repair and how much it should cost before going
          to the repair shop.
        </p>
      </div>
    </div>
    <div class="text-center">
      <img src={Image1} alt="Save $1000s" loading="lazy" class="shadow-lg" />

      <div class="text-center leading-tight mt-6">
        <p class="font-semibold text-xl mb-3">Save $1000s At The Mechanic</p>
        <p>
          Feel confident you're not getting ripped off or do repairs yourself
          with our DIY tutorials!
        </p>
      </div>
    </div>
  </div>
</Section>
