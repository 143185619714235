<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import logoImage from '@/images/bfcm-dark-logos.png'

  import FoxNewsImage from '@/images/landing-page/fox-news.png'
  import MashableImage from '@/images/landing-page/mashable-logo-dark.png'
  import EngadgetImage from '@/images/landing-page/engadget.png'
  import UsaTodayImage from '@/images/landing-page/usa-today.png'
  import DonutImage from '@/images/landing-page/donut-logo-dark.png'

  const images = [
    {
      img: MashableImage,
      alt: 'mashable',
    },
    {
      img: UsaTodayImage,
      alt: 'usa today',
    },
    {
      img: EngadgetImage,
      alt: 'engadget',
    },
    {
      img: DonutImage,
      alt: 'donut',
    },
    { img: FoxNewsImage, alt: 'fox news' },
  ]
</script>

<div class="bg-[#f2f2f2] lg:py-10">
  <Section verticalPadding="short">
    <div class="w-4/5 mx-auto">
      <div class="text-center mb-3">
        <h4 class="font-semibold">As seen on:</h4>
      </div>

      <div class="hidden xs:block">
        <img src={logoImage} alt="logos" />
      </div>

      <div class="xs:hidden">
        <Carousel
          showPagination={false}
          loop={true}
          splideOptions={{
            autoplay: true,
            interval: 2500,
          }}
        >
          {#each images as image}
            <CarouselItem>
              <div class="flex items-center justify-center h-full">
                <img
                  src={image.img}
                  alt={image.alt}
                  class={image.alt !== 'fox news' ? 'w-24' : 'w-14'}
                />
              </div>
            </CarouselItem>
          {/each}
        </Carousel>
      </div>

      <slot name="cta" />
    </div>
  </Section>
</div>
