<script>
  // NOTE: there's a more robust compatibility widget managed by FIXD Rails:
  // https://app.fixdapp.com/app/compatibility

  // This one is a recreation of the one on the LP, which
  // hard-codes a combination of MMYs and has very simple logic

  function* range(start, end, step = 1) {
    let state = start
    while (state <= end) {
      yield state
      state += step
    }
  }

  const years = Array.from(range(1996, 2020))

  const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini ',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Porsche',
    'Ram',
    'Smart',
    'Subaru',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]
  const fuelTypes = ['Gas', 'Hybrid', 'Diesel', 'Electric']

  let year = null
  let make = null
  let fuelType = null

  function isCompatible(year, make, fuelType) {
    if (year === null || make === null || fuelType === null) return null
    if (year < 1996) return false
    if (fuelType === 'Diesel' && year < 2006) return false
    if (fuelType === 'Electric') return false
    return true
  }

  $: compatible = null

  const handleSubmit = (e) => {
    e.preventDefault()
    compatible = isCompatible(year, make, fuelType)
  }
</script>

<style>
  form {
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
  }

  button.outline-button {
    border-radius: 20px;
    border: 3px solid #25ae53;
    color: #25ae53;
    background-color: #fff;
    font-size: 16px;
  }

  button.outline-button:hover {
    color: #fff;
    background-color: #25ae53;
  }
</style>

<div class="compatibility">
  <form on:submit={handleSubmit}>
    <div>
      <h4 class="text-left text-15 mb-1">Year</h4>
      <div class="form-control">
        <select
          bind:value={year}
          name="year"
          class="no-label bg-[#f3f3f3] rounded-none"
          required={true}
        >
          <option value={null}>Select One...</option>
          {#each years as year}
            <option value={year}>{year}</option>
          {/each}
        </select>

        <div class="chevron bottom absolute right-[6px] top-[14px]" />
      </div>
    </div>

    <div>
      <h4 class="text-left text-15 mb-1 mt-0">Make</h4>
      <div class="form-control">
        <select
          bind:value={make}
          name="make"
          class="no-label bg-[#f3f3f3] rounded-none"
          required={true}
        >
          <option value={null}>Select One...</option>
          {#each makes as make}
            <option value={make}>{make}</option>
          {/each}
        </select>

        <div class="chevron bottom absolute right-[6px] top-[14px]" />
      </div>
    </div>

    <div>
      <h4 class="text-left text-15 mb-1 mt-0">Fuel Type</h4>
      <div class="form-control">
        <select
          bind:value={fuelType}
          name="fuel-type"
          class="no-label bg-[#f3f3f3] rounded-none"
          required={true}
        >
          <option value={null}>Select One...</option>
          {#each fuelTypes as fuelType}
            <option value={fuelType}>{fuelType}</option>
          {/each}
        </select>
        <div class="chevron bottom absolute right-[6px] top-[14px]" />
      </div>
    </div>

    <div class="mx-auto xs:mx-0 w-[200px]">
      <button class="outline-button"> Submit </button>
    </div>
  </form>
  <p class="mt-2.5 text-22 leading-5 font-bold text-left">
    {#if compatible === null}
      <!-- Nothing -->
    {:else if compatible}
      <p class="compatible">Yes! FIXD will work with your car!</p>
      <slot name="compatible" />
    {:else}
      <p class="not-compatible">We're sorry, your car is not compatible!</p>
      <slot name="incompatible" />
    {/if}
  </p>
</div>
