<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import GuaranteeImage from '@/images/landing-page/scorpio/premium-clarity/guarantee.png'
</script>

<Section center="true">
  <div class="mb-11">
    <img src={GuaranteeImage} alt="guarantee" />
  </div>

  <div class="px-5">
    <div class="leading-tight mb-4">
      <h2>FIXD Works.</h2>
      <h2 class="italic">Or You Don't Pay</h2>
    </div>

    <div class="text-left space-y-4 font-semibold">
      <p>
        Over 3 MILLION drivers trust FIXD to help them take better care of their
        cars and save money.
      </p>
      <p>
        Join them today (for just $19.99 for a limited time) and take FIXD for a
        100% RISK-FREE test drive.
      </p>
      <p>
        If you don't love it, for whatever reason, just let us know within 30
        days of purchase, and we'll promptly refund your money.
      </p>
    </div>

    <div class="px-4">
      <slot name="cta" />
    </div>
  </div>
</Section>
