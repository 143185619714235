<script>
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Banner from '@/components/landing-page/Banner.svelte'
  import BuyBoxV2 from '@/components/landing-page/widgets/BuyBoxV2.svelte'

  import StaticReviews from '@/components/landing-page/widgets/StaticReviews.svelte'

  import { FaStarFilledRound } from '@/components/icons'

  import FoundersImage from '@/images/landing-page/scorpio/3-grads-fixd-guys.jpg'
  import PlugInImage from '@/images/landing-page/scorpio/3-grads-plug-fixd-in.jpeg'

  import PlugIcon from '@/images/landing-page/scorpio/3-grads-Plugin.jpeg'
  import DownloadIcon from '@/images/landing-page/scorpio/3-grads-Download.jpeg'
  import DiagnosticsIcon from '@/images/landing-page/scorpio/3-grads-DiagnosticIcon.jpeg'

  import LPFaq from '@/components/bfcm/LPFaq.svelte'

  export let funnel
  export let gateway

  const scrollToBuyBox = () => {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  const items = [
    {
      img: PlugIcon,
      title: '1. Plug FIXD into your car',
      description:
        'FIXD plugs straight into your car’s computer port (every car built after 1996 has this port). Installation is easy and only takes a couple minutes without any tools or car repair experience.',
    },
    {
      img: DownloadIcon,
      title: '2. Download the free app',
      description:
        'You can download our free FIXD app on iPhone or Android. It will automatically connect to your FIXD device via Bluetooth (which comes with most newer phones).',
    },
    {
      img: DiagnosticsIcon,
      title: '3. Get easy-to-read diagnostics',
      description:
        'Never fear the check engine light again! With FIXD, you can see straight from your smartphone what’s wrong with your car BEFORE you take it to the repair shop!',
    },
  ]
</script>

<main class="text-black relative">
  <div class="h-14 md:h-[90px]">
    <div class="fixed top-0 bg-white w-full z-20 shadow-lg">
      <div class="py-3.5">
        <Banner darkTheme={false} height="short" />
      </div>
    </div>
  </div>

  <Section verticalPadding="short">
    <header class="mb-3">
      <h1 class="text-25 md:text-40 md:px-2.5">
        3 Georgia Tech Grads <span class="text-green">vs.</span>
        <br class="hidden md:block" />The Billion-Dollar Auto Repair Industry
      </h1>
    </header>
    <div>
      <article class="space-y-6">
        <img src={FoundersImage} alt="founders" class="w-full" />
        <h3 class="text-22 md:text-2xl leading-normal">
          This “Dorm-Room Startup” Is Changing The Way People Service Their Cars
        </h3>
        <p class="font-bold">
          Does your Check Engine Light come on at the WORST possible time?
        </p>
        <p>
          There are THOUSANDS of reasons why a check engine light comes on, and
          figuring out what it meant always required a trip to the mechanic —
          until now!
        </p>
        <p>
          Tired of seeing their family and friends stress out over car repairs,
          three engineering students from Georgia Tech designed this <button
            class="bare"
            on:click={scrollToBuyBox}
          >
            <span class="underline">new device</span>
          </button>
          that
          <span class="font-bold"
            >instantly translates your check engine light into simple,
            easy-to-understand terms!</span
          >
        </p>
      </article>

      <article class="space-y-6 mt-6">
        <h2 class="text-25">
          It's called <button class="bare" on:click={scrollToBuyBox}
            ><span class="underline font-medium">FIXD</span></button
          > and it's a “GAME-CHANGER”
        </h2>

        <p>
          Imagine looking at your smartphone and INSTANTLY knowing if there's a
          problem with your car….how serious it is… <span class="font-bold"
            >and whether or not it's an emergency.</span
          >
        </p>
        <p>
          With FIXD, you can <span class="font-bold"
            >know what's going on with your car before going to a shop and feel
            confident</span
          > about repairs.
        </p>

        <img src={PlugInImage} alt="plug in FIXD sensor" />
      </article>

      <article class="mt-5">
        <h3
          class="font-semibold md:font-medium text-center text-2xl md:text-34 mb-5"
        >
          Demystify Your Check Engine Light In 3 Easy Steps!
        </h3>
        <div class="md:flex gap-3">
          {#each items as item}
            <div class="basis-1/3 text-center">
              <div class=" text-center">
                <img src={item.img} alt={item.title} class="w-1/4" />
              </div>
              <div class="my-3">
                <p class="text-22">{item.title}</p>
              </div>
              <p>{item.description}</p>
            </div>
          {/each}
        </div>
      </article>

      <article class="mt-10 lading-">
        <div class="text-left md:text-center mb-5 md:mb-7">
          <h2 class="font-semibold md:font-normal text-30 md:text-36">
            The best part...
          </h2>
        </div>
        <div class="space-y-5 leading-tight">
          <p class="text-lg md:text-xl font-medium">
            If and when that 'Check Engine Light' comes on, FIXD tells you
            exactly what it means and how serious it is in plain old English.
          </p>
          <p class="text-lg md:text-xl">
            No more looking up codes or wondering if your mechanic is being
            truthful — never again!
          </p>
          <p class="text-lg md:text-xl">
            You can also turn off the check engine light right from the FIXD app
            if the problem is not serious (e.g., you didn't screw the gas cap on
            tight enough) or if you want to see if the light comes back on after
            further driving.
          </p>
          <p class="text-lg md:text-xl">How cool is that?!</p>
        </div>
      </article>

      <article class="mt-10">
        <div class="text-left md:text-center mb-5 md:mb-7">
          <h2 class="font-semibold md:font-normal text-30 md:text-36">
            Do I have to pay for a subscription?
          </h2>
        </div>
        <div class="space-y-5 leading-tight">
          <p class="text-lg md:text-xl">
            Nope! The great thing about the FIXD Sensor is that it comes with a
            free app for both iPhone and Android smartphones. No subscription is
            required to read and clear your check engine light, see how serious
            the problem is, and get automated maintenance alerts.
          </p>
          <p class="text-lg md:text-xl">
            <span class="font-bold">BUT…</span> if you want to take it a step further
            with cost estimates for repairs, the most likely causes of your check
            engine light and how to fix it, access to the Mechanic Hotline, and more,
            there's an optional FIXD Premium subscription.
          </p>
        </div>
      </article>

      <article class="mt-10">
        <div class="mb-5 md:mb-7">
          <h2 class="font-bold md:font-normal text-38 leading-9">
            So this all sounds great, but how much does it cost?
          </h2>
        </div>
        <div class="space-y-5 leading-tight">
          <p class="text-lg md:text-xl">
            Something like this should cost a few hundred dollars, right?
          </p>
          <p class="text-lg md:text-xl">
            That's what makes this startup so great.
          </p>
          <p class="text-lg md:text-xl">
            The FIXD Sensor is priced affordably at only $59! But since it's the
            founders' mission to democratize auto repair by bringing transparent
            knowledge to everyone, they're making it even more affordable in
            order to get FIXD into the hands of as many drivers as possible.
          </p>
          <p class="text-lg md:text-xl">
            For a limited time, you can try FIXD for only $19.99 including FREE
            SHIPPING.
          </p>
        </div>
      </article>
    </div></Section
  >

  <div class="bg-green py-10 my-4">
    <div class="max-w-[940px] mx-auto text-center">
      <div class="text-white">
        <div class="mb-2 inline-flex gap-1 items-center">
          <div class="hidden md:block">
            <FaStarFilledRound color="white" size="3xl" />
          </div>
          <div class="md:hidden">
            <FaStarFilledRound color="white" size="xl" />
          </div>
          <div class="mt-1">
            <h1 class="text-2xl md:text-4xl">Important Information!</h1>
          </div>
          <div class="hidden md:block">
            <FaStarFilledRound color="white" size="3xl" />
          </div>
          <div class="md:hidden">
            <FaStarFilledRound color="white" size="xl" />
          </div>
        </div>
        <div class="text-xl">
          <div class="md:inline-flex items-center gap-1">
            <p class="leading-tight">WHILE SUPPLIES LAST,</p>
            <p>GET FIXD FOR ONLY $19.99!</p>
          </div>
          <p>
            <span class="font-bold">67% OFF</span> plus
            <span class="font-bold">FREE Shipping!</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <Section center="true" verticalPadding="short" horizontalPadding="none">
    <div class="bg-white md:py-4 rounded-3xl">
      <div class="max-w-[325px] md:max-w-screen-md mx-auto">
        <BuyBoxV2 {funnel} {gateway} />
      </div>
    </div>
  </Section>

  <div class="max-w-[940px] mx-auto">
    <LPFaq />
  </div>

  <Section center="true" verticalPadding="short">
    <div class="max-w-[940px] mx-auto">
      <div class="text-center my-2">
        <h2 class="text-4xl">Reviews</h2>
      </div>
      <StaticReviews />
    </div>
  </Section>

  <Footer />
  <div class="fixed left-0 right-0 bottom-4 w-11/12 mx-auto xs:hidden">
    <div class="w-full shadow-lg">
      <button class="small-button" on:click={scrollToBuyBox}>
        <span class="text-15">Buy FIXD Now! Only $19.99!</span>
      </button>
    </div>
  </div>
</main>
