<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Video from '@/images/landing-page/webflow/real-time-health-monitoring.mp4'

  $: video = null
  $: videoPaused = false

  const toggleVideo = (video) => {
    const playing = isVideoPlaying(video)

    playing ? video.pause() : video.play()
  }

  const isVideoPlaying = (video) => {
    return !!(
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    )
  }
</script>

<style>
  .play-button {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #fff;
    border-bottom: 15px solid transparent;
  }
</style>

<div class="py-[5%]">
  <Section center="true" verticalPadding="none">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto">
      <div class="xs:flex flex-row-reverse text-left">
        <div class="basis-1/2">
          <div class="flex justify-center md:justify-start md:pl-5">
            <div class="relative">
              <video
                bind:this={video}
                bind:paused={videoPaused}
                src={Video}
                alt="understand your car"
                autoplay
                loop
                muted
                type="video/mp4"
                class="max-w-[300px] sm:max-w-[330px]"
              />
              <div class="absolute bottom-4 right-4">
                <div
                  role="button"
                  tabindex={-1}
                  class="rounded-full bg-black h-11 w-11 flex items-center justify-center"
                  on:click={() => {
                    toggleVideo(video)
                  }}
                  on:keypress={() => {
                    toggleVideo(video)
                  }}
                >
                  {#if videoPaused}
                    <div class="ml-1.5 play-button" />
                  {:else}
                    <div
                      class="flex gap-1.5 h-full w-full items-center justify-center"
                    >
                      <div class="h-3/5 w-2 bg-white rounded-sm" />
                      <div class="h-3/5 w-2 bg-white rounded-sm" />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 mt-2.5 sm:mt-0 basis-1/2 items-center px-2.5">
          <div>
            <p class="uppercase text-[#857550]">real-time health monitoring</p>
            <p class="text-30 leading-8 my-2.5">
              Keeps Your Car Running Smoothly
            </p>
          </div>
          <div class="space-y-4">
            <p class="text-15">
              FIXD is the only car scanner in the world that continuously
              monitors your vehicle and automatically alerts you when you’re due
              for an oil change, tire rotation, & more.
            </p>

            <p class="text-15">
              If you catch and fix the problems early with basic maintenance,
              then you’ll avoid bigger bills and major problems down the line.
            </p>

            <p class="text-15">
              Don't learn how to keep your car going the hard way. FIXD saves
              you the time, money and pain of car troubles.
            </p>
          </div>
          <slot name="cta" />
        </div>
      </div>
    </div>
  </Section>
</div>
