<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import GuaranteeImage from '@/images/landing-page/webflow/30-days-guarantee.png'
  import AmexImage from '@/images/landing-page/webflow/payment-amex.svg'
  import DiscoverImage from '@/images/landing-page/webflow/payment-discover.svg'
  import MastercardImage from '@/images/landing-page/webflow/payment-mastercard.svg'
  import PaypalImage from '@/images/landing-page/webflow/payment-paypal.svg'
  import ShopifyImage from '@/images/landing-page/webflow/payment-shopify.svg'
  import VisaImage from '@/images/payment-methods/visa.svg'
</script>

<div class="bg-[#efefef] py-2.5 md:py-0">
  <Section center="true" verticalPadding="none">
    <div class="flex">
      <div class="flex flex-wrap justify-center items-center">
        <div class="md:flex-1 md:mx-5 m-1 w-[15%]">
          <img
            src={GuaranteeImage}
            alt="30-days-guarantee"
            class="md:h-[100px] w-full"
          />
        </div>
        <div class="md:flex-1 md:mx-5 m-1 w-[15%]">
          <img
            src={AmexImage}
            alt="american-express"
            class="md:h-[100px] w-full"
          />
        </div>
        <div class="md:flex-1 md:mx-5 m-1 w-[15%]">
          <img src={DiscoverImage} alt="discover" class="md:h-[100px] w-full" />
        </div>
        <div class="md:flex-1 md:mx-5 m-1 w-[15%]">
          <img
            src={MastercardImage}
            alt="master-card"
            class="md:h-[100px] md:w-16 w-full"
          />
        </div>
        <div class="md:flex-1 md:mx-5 m-1 w-[15%]">
          <img
            src={VisaImage}
            alt="master-card"
            class="md:h-[100px] md:w-17 w-full"
          />
        </div>
        <div class="md:flex-1 md:mx-5 m-1 w-[22%] md:w-[15%]">
          <img src={PaypalImage} alt="paypal" class="md:h-[100px] w-full" />
        </div>
        <div class="md:flex-1 md:mx-5 m-1 w-[22%] md:w-[15%]">
          <img src={ShopifyImage} alt="shopify" class="md:h-[100px] w-full" />
        </div>
      </div>
    </div>
  </Section>
</div>
