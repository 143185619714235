<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'
  import ImageOne from '@/images/multi-vehicle-white-comp.png'
  import ImageTwo from '@/images/issue-severity-white-phone-comp.png'
  import ImageThree from '@/images/mechanic-hotline-call-white-phone-comp.png'
  import ImageFour from '@/images/historical-scan-white-phone-comp.png'
  import ImageFive from '@/images/clear-cel-white-comp.png'

  const images = [
    {
      imgSrc: ImageTwo,
      altText: 'Never get ripped off',
      title: 'Never get ripped off',
      description:
        "Know what's wrong before going to the shop. You can even see how much repairs should cost* so you don't get taken for a ride",
    },
    {
      imgSrc: ImageFour,
      altText: 'Prevent costly repairs',
      title: 'Prevent costly repairs',
      description:
        "FIXD tells you when it's time for maintenance so you can keep your car running smoothly and avoid expensive issues",
    },
    {
      imgSrc: ImageThree,
      altText: 'Fix it yourself & save $$$',
      title: 'Fix it yourself & save $$$',
      description:
        'Learn how to fix simple issues yourself and avoid labor fees with live OBD2 data, our AI Mechanic*, and FIXD Mechanic Hotline*',
    },
    {
      imgSrc: ImageFive,
      altText: 'Clear check engine lights',
      title: 'Clear check engine lights',
      description:
        "Mechanics charge $100+ to diagnose one light. Now you can diagnose over 7,000 check engine lights on your phone and clear them with the touch of a button if it's nothing serious.",
    },
    {
      imgSrc: ImageOne,
      altText: 'Monitor the whole family',
      title: 'Monitor the whole family',
      description:
        'Protect your vehicles - and your loved ones. Easily track maintenance and car health for all your vehicles in one app.',
    },
  ]
</script>

<div class="bg-black lg:py-8">
  <Section center={true}>
    <div class="text-white">
      <div>
        <div class="mb-3">
          <h1 class="text-30 lg:text-4xl">More than just a code reader</h1>
        </div>
        <p class="text-15">Here's how FIXD can save you $1000s on repairs</p>
      </div>

      <Carousel showArrows={true} arrowSize="lg" loop={true}>
        {#each images as item}
          <CarouselItem>
            <div class="text-center">
              <div>
                <img
                  src={item.imgSrc}
                  alt={item.altText}
                  class="w-11/12 md:w-5/12"
                />
              </div>
              <div class="mb-2">
                <h3>{item.title}</h3>
              </div>
              <div class="w-4/5 text-center mx-auto">
                <p class="text-15">{item.description}</p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
      <div>
        <p class="italic text-15">
          *Premium features included free with 14-day trial.
        </p>
      </div>
    </div>
  </Section>
</div>
