<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  function jumpToBuyBox() {
    document.querySelector('.buy-box').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<Section
  additionalClass="max-w-[1085px] mx-auto"
  maxWidth="xl"
  verticalPadding="short"
>
  <div class="bg-black-1 text-white rounded-xl py-5 md:py-13 md:my-10">
    <div class="max-w-[846px] mx-auto px-4 md:px-0">
      <h2 class="mb-8 text-center md:text-left">Frequently Asked Questions</h2>
      <div class="px-3 space-y-8 leading-tight">
        <div>
          <p class="font-semibold mb-2.5">Q: Will FIXD work with my car?</p>
          <p>
            A: FIXD works with all gas-powered vehicles made after 1995 and all
            diesel engines made after 2007.
          </p>
        </div>
        <div>
          <p class="font-semibold mb-2.5">Q: Is it easy to use?</p>
          <p>
            A: Yes! FIXD was designed for anyone to use - even if you know
            nothing about cars! It translates issues into easy-to-understand
            terms so you can make informed decisions and avoid overpaying at the
            repair shop.
          </p>
        </div>

        <div class="space-y-4">
          <p class="font-semibold mb-2.5">
            Q: I'm skeptical... can a $20 scan tool really diagnose my car?
          </p>
          <p>
            A: You bet. The founders of FIXD built this tool from the ground up
            to work with a state-of-the-art app so that everyone could have
            access to the same information mechanics have.
          </p>
          <p>
            Their goal is to make this scanner as affordable as possible to
            empower you at the repair shop.
          </p>
          <div>
            <p>
              Unlike bulky pro scanners, FIXD doesn't cost $2000 and doesn't
              just read out codes. It easily fits in your glovebox and connects
              to your phone to tell you:
            </p>
            <ul class="list-disc pl-5">
              <li>What your check engine light means</li>
              <li>How serious it is</li>
              <li>How much it should cost</li>
            </ul>
          </div>
          <p>
            FIXD even tracks maintenance so you can prevent costly repairs in
            the first place.
          </p>
          <div
            on:click={jumpToBuyBox}
            role="button"
            tabindex={-1}
            on:keypress={jumpToBuyBox}
          >
            <p class="text-green font-bold underline">
              Get yours today while it's still 67% OFF!
            </p>
          </div>
        </div>

        <div>
          <p class="font-semibold mb-2.5">Q: What codes does FIXD read?</p>
          <p>
            A: FIXD translates over 7000 check engine codes, including all
            standardized codes on most gas-powered vehicles built after 1995.
            Enhanced codes, including ABS, airbag, transmission, & TPMS are not
            supported.
          </p>
        </div>
        <div>
          <p class="font-semibold mb-2.5">
            Q: Does it work with iPhone & Android?
          </p>
          <p>
            A: Yes, the FIXD app works with both iPhone and Android smartphones.
          </p>
        </div>
        <div>
          <p class="font-semibold mb-2.5">
            Q: Do I have to pay for a subscription?
          </p>
          <p>
            A: No. You can use your FIXD Sensor with our free app to read and
            clear engine lights, track maintenance, & more. However, your sensor
            comes with a 14-day free trial of FIXD Premium (reg. $99/year) so
            you can try everything FIXD has to offer as soon as your sensor
            arrives!
          </p>
        </div>
      </div>
    </div>
  </div>
</Section>
