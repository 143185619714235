<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import ImageOne from '@/images/landing-page/webflow/start-1.jpg'
  import ImageTwo from '@/images/landing-page/webflow/start-2.jpg'
  import ImageThree from '@/images/landing-page/webflow/start-3.jpg'

  const images = [
    {
      uri: ImageOne,
      alt: 'image-1',
      title: 'Step 1',
      description: 'Plug in under the steering wheel',
    },
    {
      uri: ImageTwo,
      alt: 'image-2',
      title: 'Step 2',
      description: 'Pair FIXD with your phone',
    },
    {
      uri: ImageThree,
      alt: 'image-3',
      title: 'Step 3',
      description: 'Get notified of any issues',
    },
  ]
</script>

<Section center="true" verticalPadding="none" horizontalPadding="none">
  <div class="mb-2.5">
    <h2 class="text-2xl mb-2.5">Start Using FIXD in Seconds</h2>
    <p class="text-15">Here's how it works</p>
  </div>
  <div class="md:flex">
    {#each images as item}
      <div class="w-full relative">
        <div
          class="bg-black-0 absolute top-0 left-0 right-0 w-full h-full z-[99 opacity-50"
        />
        <img
          src={item.uri}
          alt={item.altText}
          lazy
          class="h-[250px] w-full object-cover"
        />
        <div
          class="absolute top-0 left-0 h-full w-full text-white text-15 flex items-center flex-col justify-center"
        >
          <div class="mb-4 uppercase">
            <p>{item.title}</p>
          </div>
          <p class="font-bold">{item.description}</p>
        </div>
      </div>
    {/each}
  </div>
</Section>
