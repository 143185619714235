<script>
  import CheckMarkImage from '@/images/landing-page/check1.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  const list = [
    {
      title: 'Check Engine Diagnostics',
      description:
        'Skip the $150 diagnostic fee. FIXD instantly tells you what your check engine light means and how serious it is. No car knowledge needed.',
    },
    {
      title: 'Upfront Cost Estimates*',
      description:
        "Know how much repairs should cost BEFORE going to the mechanic so you don't get taken for a ride!",
    },
    {
      title: 'Mechanic Hotline*',
      description: 'Get unbiased, expert advice from our friendly mechanics.',
    },
    {
      title: 'Real-Time Data',
      description:
        'Monitor the overall health of your vehicles in real-time using live data.',
    },
    {
      title: 'Clear Warning Lights',
      description:
        'Check engine light nothing serious? Turn it off with the touch of a button.',
    },
    {
      title: 'Maintenance Tracker',
      description:
        'Never miss another oil change and keep your car running smoothly with automatic maintenance alerts.',
    },
    {
      title: 'Diagnostic Flowchart',
      description:
        "Car running rough? Strange noises? Diagnose the problem from your phone - even if there isn't a warning light.",
    },
    {
      title: 'Issue Forecast*',
      description:
        'Predict what problems your car is most likely to face in the future so you can take steps to avoid them and save $1000s.',
    },
    {
      title: 'Vehicle History Report*',
      description:
        'Find out whether that used car is a great deal or a lemon (similar reports cost $40+ each).',
    },
    {
      title: 'AI Mechanic*',
      description: 'Get instant answers to all your car questions 24/7.',
    },
  ]
</script>

<Section
  background="green-300"
  maxWidth="xl"
  additionalClass="rounded-xl md:rounded-none mx-3 md:mx-0 mb-5 md:mb-0"
>
  <h3 class="text-center font-semibold mb-8">
    Here's everything you get when you try FIXD for just $19.99:
  </h3>
  <div class="space-y-4 md:space-y-0">
    {#each list as item}
      <div class="flex gap-4 items-center">
        <div class="shrink-0">
          <img
            src={CheckMarkImage}
            alt="checkmark"
            class="size-16 md:size-20"
          />
        </div>
        <div class="leading-tight">
          <p class="font-semibold">
            {item.title}
          </p>
          <p>{item.description}</p>
        </div>
      </div>
    {/each}
  </div>
  <div class="text-center mt-4">
    <p class="text-15">
      *These features are included FREE with your 14-day FIXD Premium app trial!
    </p>
  </div>
  <slot name="cta" />
</Section>
