<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'
  import YellowFiveStar from '@/images/icon/Yellow-5-Stars.png'
  import { CheckmarkFilled } from '@/components/icons'

  const cards = [
    {
      title: 'Saved me from being stranded!',
      description:
        'This is one of my favorite gadgets of all time. No more going to a shop to get the check engine codes checked and interpreted by someone else. It also alerted me while I was driving that I had two new codes. I drove immediately to the place I intended to get it repaired and the mechanic told me if I hadn’t brought it in when I did, I might have to pay for much more costly repairs and my car breaking down on me.The app is also constantly being updated and made better. No complaints there either.',
      name: 'Dona',
      url: null,
    },
    {
      title: 'Well worth the money!',
      description:
        "We bought 3 of them so we could monitor the wife's and daughters car even when they are not at home. What a great product and well worth the money!! About time that someone used their head to market a product that is not only useful but gives the dad peace of mind and help with service reminders as well!!! Great job, guys!!!!!!",
      name: 'April M.',
      url: 'https://www.amazon.com/gp/customer-reviews/R1C6HVICZK8NC2/ref=cm_cr_getr_d_rvw_ttl?ie=UTF8&ASIN=B013RIQMEO&adb_sid=09245f23-2f34-461e-9ab8-f4a92c72c486',
    },
    {
      title: 'Great peace of mind!',
      description:
        'Had a check engine light come on during a trip, this device told us that it was not a critical issue and would not affect the performance of the car. BIG relief to know the car was not going to break down on our trip. Allowed us to tell the repair show exactly what the issue was when we had time. A real life saver!',
      name: 'Ra B.',
      url: 'https://www.amazon.com/gp/customer-reviews/R3K4NA2F104AXC/ref=cm_cr_arp_d_rvw_ttl?ie=UTF8&ASIN=B07CFVD2P3&adb_sid=09245f23-2f34-461e-9ab8-f4a92c72c486',
    },
    {
      title: 'Best Device Ever!!',
      description:
        'This device is the best things have seen to save you money. I have used this many times to clear check engine light on vehicles that were not critical, and have discovered other problems that came up and was able to fix without having to pay a mechanic tons of money for. Highly recommend this, have one in each of my vehicles.',
      name: 'Carlton M.',
      url: 'https://www.amazon.com/gp/customer-reviews/R4XCWZUZHYKJJ/ref=cm_cr_getr_d_rvw_ttl?ie=UTF8&ASIN=B013RIQMEO&adb_sid=09245f23-2f34-461e-9ab8-f4a92c72c486',
    },
  ]
</script>

<style>
  .card-container {
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  }
</style>

<Section center="true" verticalPadding="short" webflow={true}>
  <div class="mb-8">
    <h2 class="font-medium text-30">Here's Why People Love FIXD...</h2>
  </div>
  <div>
    <Carousel
      showPagination={true}
      showArrows={true}
      perPage={3}
      loop={true}
      splideOptions={{
        perPage: 2,
        gap: 20,
        breakpoints: {
          420: {
            perPage: 1,
          },
        },
      }}
    >
      {#each cards as item}
        <CarouselItem>
          <div
            class="card-container text-left w-[90%] md:px-10 mx-auto py-2.5 mb-5 px-5 h-[95%] pb-8"
          >
            <div>
              <header class="mb-2.5">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  class="text-lg text-black {item.url
                    ? 'underline'
                    : 'no-underline'}"
                >
                  {item.title}
                </a>
              </header>
              <div class="mb-12">
                <p class="text-15">
                  {item.description}
                </p>
              </div>
              <footer>
                <div class="flex justify-between">
                  <div>
                    <div class="font-bold text-15">{item.name}</div>
                    <div class="flex items-center gap-1">
                      <CheckmarkFilled color="green" size="sm" />
                      <p class="text-xs text-green">Verified Buyer</p>
                    </div>
                  </div>
                  <div class="text-right">
                    <img src={YellowFiveStar} alt="5-stars" width={84} />
                    <p class="text-15">5.0 star rating</p>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </CarouselItem>
      {/each}
    </Carousel>
    <slot name="cta" />
  </div>
</Section>
