<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import RiskFreeImage from '@/images/landing-page/webflow/savings-icon.png'
  import FlagImage from '@/images/landing-page/webflow/usa.png'
  import FreeShippingImage from '@/images/landing-page/webflow/free-shipping.png'
  import FiveStarHandIcon from '@/images/landing-page/webflow/5-stars-hand-icon.png'
  import ConversationImage from '@/images/landing-page/webflow/conversation.png'
  import RibbonImage from '@/images/landing-page/webflow/ribbon.png'

  const items = [
    {
      uri: RiskFreeImage,
      title: '30-day money back guarantee',
      description:
        'If for any reason you’re not 100% satisfied with FIXD, you can easily return it, hassle-free!',
    },
    {
      uri: FlagImage,
      title: 'american-owned company',
      description:
        'If for any reason you’re not 100% satisfied with FIXD, you can easily return it, hassle-free!',
    },
    {
      uri: FreeShippingImage,
      title: 'FREE, FAST SHIPPING IN THE USA',
      description: 'We also provide fast shipping to over 100 countries.',
    },
    {
      uri: FiveStarHandIcon,
      title: 'quality promise',
      description:
        'We control every step of the process to ensure a safe and high quality product.',
    },
    {
      uri: ConversationImage,
      title: 'world class customer support',
      description:
        "They're real humans, they're quick, and they actually like talking to you.",
    },
    {
      uri: RibbonImage,
      title: 'Mission-driven',
      description:
        'Our mission is to empower millions of car owners to save time, save money, and simplify car care.',
    },
  ]
</script>

<style>
  .card-container {
    box-shadow: 0 2px 10px 3px rgb(0 0 0 / 6%);
  }
</style>

<div class="bg-[#f5f4f2]">
  <Section center="true" verticalPadding="short">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto">
      <header class="text-center mb-2.5">
        <div class="mb-2.5">
          <p class="text-2xl">Your Satisfaction Is Our Priority</p>
        </div>
        <p class="text-15">
          Try FIXD risk-free and if you don’t love it, we’ll fix it.
        </p>
      </header>
      <div>
        <div class="flex flex-wrap">
          {#each items as item}
            <div class="w-1/2 md:w-1/3">
              <div
                class="py-5 px-1 md:py-10 text-center bg-white card-container m-1 md:m-2 min-h-[282px]"
              >
                <div class="h-16 w-full flex items-center justify-center">
                  <img src={item.uri} alt={item.title} class="w-16 md:w-14" />
                </div>
                <div class="my-2 5">
                  <p class="font-bold uppercase text-sm md:text-xl leading-6">
                    {item.title}
                  </p>
                </div>
                <p class="text-15">{item.description}</p>
              </div>
            </div>
            <!-- content here -->
          {/each}
        </div>
      </div>
    </div>
  </Section>
</div>
