<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import ImageOne from '@/images/landing-page/webflow/more-reasons-1.jpg'
  import ImageTwo from '@/images/landing-page/webflow/more-reasons-2.jpg'
  import ImageThree from '@/images/landing-page/webflow/more-reasons-3.jpg'

  const images = [
    {
      uri: ImageOne,
      title: 'MONITOR ALL OF YOUR CARS IN ONE PLACE',
      description:
        'Our free app makes it easy to connect multiple FIXD sensors to one account so you can monitor your family of vehicles in real time – right from your smartphone.',
    },
    {
      uri: ImageTwo,
      title: 'ONE SENSOR FOR THE WHOLE FAMILY',
      description:
        "Scan and monitor all of your family's vehicles - up to 10 - with just one account. No other car product offers so much value for such a small purchase.",
    },
    {
      uri: ImageThree,
      title: 'CLEAR THE CHECK ENGINE LIGHT',
      description:
        "Clear any check engine light. If it's just a loose gas cap or something you can fix then there’s no need to go to a mechanic.",
    },
  ]
</script>

<div class="bg-[#f2f1ef] py-4">
  <Section center="true" verticalPadding="none">
    <div class="mb-2.5">
      <h2 class="text-30 mb-10">More Reasons Every Driver Loves FIXD</h2>
    </div>
    <div class="text-left">
      <div class="md:flex">
        {#each images as item}
          <div class="mb-2.5 md:px-2.5 basis-1/3">
            <div class="mb-2.5">
              <img src={item.uri} alt={item.title} />
            </div>
            <div class="my-2 5">
              <p class="text-xl font-bold leading-6">{item.title}</p>
            </div>
            <p class="text-15">
              {item.description}
            </p>
          </div>
        {/each}
      </div>
    </div>
  </Section>
</div>
