<script>
  import Founders from '@/images/landing-page/scorpio/premium-clarity/about-us.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<Section center="true" verticalPadding="none">
  <div class="max-w-[640px] mx-auto md:pt-5">
    <h2>Meet The Founders</h2>
    <div class="my-8">
      <img
        src={Founders}
        alt="FIXD founders"
        loading="lazy"
        class="rounded-xl"
      />
    </div>

    <div class="space-y-4 leading-tight font-semibold text-left">
      <p>
        Headquartered in Atlanta, Georgia, USA, FIXD is proud to be an
        American-owned company.
      </p>
      <p>
        FIXD was founded in 2016 by 3 GA Tech grads - John Gattuso, Julian
        Knight, and Frederick Grimm. They were tired of seeing friends and
        family get overcharged at the repair shop, and they wanted to do
        something about it.
      </p>
      <p>
        3 million+ FIXD sensors later, FIXD is fast becoming America and
        Canada's favorite car scanner. And the founders' mission hasn't changed
        - we're still committed to making car ownership safer, simpler, and more
        affordable for everyone!
      </p>
    </div>
  </div>
</Section>
