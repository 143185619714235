<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'hourglass'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  viewBox="0 0 12 16"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M8.93783 3.5399V3.0405H2.99509V3.5399C2.99509 3.5399 3.89903 6.05591 5.5958 7.21803V8.42759C5.5958 8.42759 3.72489 10.2477 2.99509 12.3409V12.9114H8.93783V12.3409C7.98984 10.0302 6.33779 8.42759 6.33779 8.42759V7.21803C8.64442 5.23531 8.93783 3.5399 8.93783 3.5399Z"
    fill="#25AE4E"
  />
  <path
    d="M11.0798 2.61291V1.7137H11.9322V0H0V1.7137H0.853126V2.61291C0.853126 3.98509 3.3156 7.73234 3.4308 8.00339C3.31764 8.2724 0.853126 12.021 0.853126 13.3878V14.2856H0V16H11.9322V14.2856H11.0798V13.3878C11.0798 12.0156 8.61664 8.29138 8.48518 8C8.61664 7.70862 11.0798 3.98441 11.0798 2.61291ZM10.228 13.3878V14.2856H1.70557V13.3878C1.70557 12.4018 4.31713 8.49399 4.31713 8C4.31713 7.50601 1.70557 3.59885 1.70557 2.61291V1.7137H10.2287V2.61291C10.2287 3.59885 7.59546 7.50601 7.59546 8C7.59478 8.49399 10.228 12.4018 10.228 13.3878Z"
  />
</svg>
