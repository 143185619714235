<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { Lightning } from '@/components/icons'

  const items = [
    'FREE 14-Day Premium App Trial',
    'FREE Fast Shipping',
    'FREE 1-Year Warranty',
    'Risk-Free Money Back Guarantee',
  ]
</script>

<Section
  center="true"
  verticalPadding="short"
  background="light-grey"
  additionalClass="rounded-xl md:rounded-none mx-3 md:mx-0 mb-5 md:mb-0 mt-5 md:mt-24"
>
  <div class="md:py-5">
    <h2 class="mb-5">
      Try FIXD Today & <span class="text-green">Save 67%!</span>
    </h2>

    <div
      class="leading-tight text-center mx-auto max-w-[300px] font-semibold space-y-3"
    >
      {#each items as item}
        <div class="text-left">
          <Lightning color="green" />
          <span>{item}</span>
        </div>
      {/each}
      <slot name="cta" />
    </div>
  </div>
</Section>
