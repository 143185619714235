<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import ImageOne from '@/images/landing-page/webflow/slide-1.jpg'
  import ImageTwo from '@/images/landing-page/webflow/slide-2.jpg'
  import ImageThree from '@/images/landing-page/webflow/slide-3.jpg'
  import ImageFour from '@/images/landing-page/webflow/slide-4.jpg'
  import ImageFive from '@/images/landing-page/webflow/slide-5.jpg'
  import ImageSix from '@/images/landing-page/webflow/slide-6.jpg'
  import ImageSeven from '@/images/landing-page/webflow/slide-7.jpg'
  import ImageEight from '@/images/landing-page/webflow/slide-8.jpg'
  import ImageNine from '@/images/landing-page/webflow/slide-9.jpg'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  const images = [
    {
      uri: ImageOne,
      alt: 'image-1',
    },
    {
      uri: ImageTwo,
      alt: 'image-2',
    },
    {
      uri: ImageThree,
      alt: 'image-3',
    },
    {
      uri: ImageFour,
      alt: 'image-4',
    },
    {
      uri: ImageFive,
      alt: 'image-5',
    },
    {
      uri: ImageSix,
      alt: 'image-6',
    },
    {
      uri: ImageSeven,
      alt: 'image-7',
    },
    {
      uri: ImageEight,
      alt: 'image-8',
    },
    {
      uri: ImageNine,
      alt: 'image-9',
    },
  ]
</script>

<div class="bg-[#ece7df] py-4">
  <Section center="true" verticalPadding="none" webflow={true}>
    <div class="mb-2.5">
      <h2 class="text-30 mb-2.5">Say GoodBye To Car Problem Anxiety</h2>
      <p class="text-lg">
        With FIXD, you know the health of your car at all times.
      </p>
    </div>
    <div>
      <Carousel
        showPagination={true}
        showArrows={true}
        perPage={3}
        loop={true}
        splideOptions={{
          autoplay: true,
          perPage: 3,
          gap: 20,
          breakpoints: {
            480: {
              perPage: 1,
            },
          },
        }}
      >
        {#each images as item}
          <CarouselItem>
            <div class="mx-auto text-center">
              <img src={item.uri} alt={item.altText} lazy />
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
    <slot name="cta" />
  </Section>
</div>
