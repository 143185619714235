<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import PlugVideo from '@/images/bfcm-plug-in.mp4'
  import StartCarVideo from '@/images/bfcm-start-car.mp4'
  import TapScanVideo from '@/images/bfcm-tap-scan.mp4'

  const cards = [
    {
      imgSrc: PlugVideo,
      title: 'Step 1: Plug in FIXD',
      description:
        'FIXD plugs into your OBD2 port (every car made since 1996 has one), usually located under the steering wheel.',
    },
    {
      imgSrc: StartCarVideo,
      title: 'Step 2: Start your engine',
      description:
        "Hop in the driver's seat and open the FIXD app. FIXD easily connects to your smartphone via Bluetooth",
    },
    {
      imgSrc: TapScanVideo,
      title: "Step 3: See what's going on",
      description: `Tap "scan" in the app to see what your check engine light means, how serious it is, and how to fix it.`,
    },
  ]
</script>

<div class="py-[10%] lg:py-[5%]">
  <Section verticalPadding="short">
    <div class="text-center mb-3">
      <h1 class="font-bold text-2xl mb-2">
        Diagnosing car problems <span class="text-green">just got easier</span>
      </h1>
    </div>

    <div class="flex justify-between gap-10 flex-col lg:flex-row">
      {#each cards as card}
        <div class="text-center">
          <div class="flex justify-center lg:h-36">
            <video
              src={card.imgSrc}
              alt={card.title}
              autoplay
              loop
              muted
              type="video/mp4"
              height="140"
              width="140"
            />
          </div>
          <div class="mb-3 lg:w-3/4 mx-auto">
            <h2>{card.title}</h2>
          </div>
          <p class="text-15">
            {card.description}
          </p>
        </div>
      {/each}
    </div>

    <slot name="cta" />
  </Section>
</div>
