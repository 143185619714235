<!-- This button will smooth scroll the user to the BuyBox, which means
it assumes the BuyBox is somewhere on the page. -->
<script>
  import Tracker from '@/util/tracker.js'
  export let scrollToBuyBox = true
  export let trackName

  let loading = false
  function jumpToBuyBox() {
    if (trackName) {
      Tracker.track('button clicked', { name: trackName })
    }
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
  const linkToCheckout = () => {
    loading = true
    window.location.href = '/buy/sensor67/1pk67pt'
  }
</script>

<style>
  button {
    color: #292929;
    background-color: #eeb738;
    background-image: linear-gradient(#f3cc70, #eeb633);
    border: 1px solid #bd9127;
    border-radius: 7px;
    padding: 20px 40px;
    font-size: 22px;
    font-weight: 700;
  }
</style>

<button
  on:click={() => (scrollToBuyBox ? jumpToBuyBox() : linkToCheckout())}
  class="buy"
  class:disabled={loading}
  disabled={loading}
>
  ORDER FIXD NOW
</button>
