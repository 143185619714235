<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import OneSensorImage from '@/images/landing-page/webflow/one-sensor.webp'
  import TwoSensorImage from '@/images/landing-page/webflow/two-sensor.webp'
  import ThreeSensorImage from '@/images/landing-page/webflow/three-sensor.webp'
  import StarImage from '@/images/landing-page/webflow/star-round.svg'
</script>

<div class="bg-[#efefef]">
  <Section center="true" verticalPadding="short" horizontalPadding="none">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto px-0.5">
      <header class="text-center mb-2.5">
        <div class="mb-2.5">
          <h3 class="">Save $1,000s In Car Repairs</h3>
        </div>
        <p class="text-lg">Get 67% off FIXD</p>
      </header>
      <div class="flex gap-3 flex-col">
        <div class="border border-black-1 bg-white px-2.5 py-5">
          <div class="flex">
            <div class="w-1/3">
              <img
                src={OneSensorImage}
                class="w-[90px] h-auto"
                alt="single-sensor"
              />
            </div>
            <div class="text-left text-15 ml-2.5">
              <div class="mb-4">
                <p><span class="font-bold">1- FIXD</span> + FREE USA S&H</p>
                <p class="font-bold">
                  Retails <span class="strikethrough">$59.99</span>
                </p>
                <p class="font-bold">
                  Now <span class="text-green">$19.99</span>
                </p>
              </div>
              <div>
                <a
                  href="/buy/sensor67/1pk67pt"
                  class="py-2 px-4 no-underline bg-[#f1c65f] rounded border border-black font-bold text-black"
                  >ORDER NOW</a
                >
                <a
                  href="/buy/sensor67/1pk67pt"
                  class="block font-bold text-black mt-2"
                  ><span class="no-underline">+ </span>Add Lifetime warranty</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="border border-black-1 bg-white px-2.5 py-5">
          <div class="flex">
            <div class="w-1/3">
              <img
                src={TwoSensorImage}
                class="w-[90px] h-auto"
                alt="single-sensor"
              />
            </div>
            <div class="text-left text-15 ml-2.5">
              <div class="bg-red inline-flex px-2">
                <img
                  src={StarImage}
                  width={14}
                  height={14}
                  alt="star"
                  class="mt-1"
                />
                <div class="text-white px-0.5">Most Popular</div>
                <img src={StarImage} width={14} alt="star" class="mt-1" />
              </div>
              <div class="mb-4">
                <p class="font-bold">2 Pack - FIXD</p>
                <p class="font-bold">+FREE USA SHIPPING</p>
                <p>
                  Retails <span class="strikethrough">$118</span>
                </p>
                <p class="font-bold text-green">Total $39.98</p>
              </div>
              <div>
                <a
                  href="/buy/sensor67/2pk67pt"
                  class="py-2 px-4 no-underline bg-[#f1c65f] rounded border border-black font-bold text-black"
                  >ORDER NOW</a
                >
                <a
                  href="/buy/sensor67/2pk67ptw"
                  class="block font-bold text-black mt-2"
                  ><span class="no-underline">+ </span>Add Lifetime warranty</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="border border-black-1 bg-white px-2.5 py-5">
          <div class="flex">
            <div class="w-1/3">
              <img
                src={ThreeSensorImage}
                class="w-[90px] h-auto"
                alt="single-sensor"
              />
            </div>

            <div class="text-left text-15 ml-2.5">
              <div class="bg-green inline-flex px-2">
                <img
                  src={StarImage}
                  width={14}
                  height={14}
                  alt="star"
                  class="mt-1"
                />
                <div class="text-white px-0.5">Best for families</div>
                <img src={StarImage} width={14} alt="star" class="mt-1" />
              </div>
              <div class="mb-4">
                <p class="font-bold">3 Pack - FIXD</p>
                <p class="font-bold">
                  +FREE USA SHIPPING <span class="font-normal"
                    >Retail <span class="strikethrough">$177</span></span
                  >
                </p>

                <p class="font-bold text-green">Total $59.97</p>
              </div>
              <div>
                <a
                  href="/buy/sensor67/3pk67pt"
                  class="py-2 px-4 no-underline bg-[#f1c65f] rounded border border-black font-bold text-black"
                  >ORDER NOW</a
                >
                <a
                  href="/buy/sensor67/3pk67ptw"
                  class="block font-bold text-black mt-2"
                  ><span class="no-underline">+ </span>Add Lifetime warranty</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></Section
  >
</div>
