<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import AndroidiPhoneImage from '@/images/landing-page/webflow/android-iphone.png'
  import ConversationImage from '@/images/landing-page/webflow/conversation.png'
  import FiveStarHandIcon from '@/images/landing-page/webflow/5-stars-hand-icon.png'
  import RiskFreeImage from '@/images/landing-page/webflow/savings-icon.png'

  const images = [
    {
      uri: AndroidiPhoneImage,
      alt: 'business insider',
      title: 'available on',
      description: 'Android & iPhone',
    },
    {
      uri: ConversationImage,
      alt: 'business insider',
      title: 'over 3 million',
      description: 'Sensors Sold!',
    },
    {
      uri: FiveStarHandIcon,
      alt: 'business insider',
      title: '10,000+',
      description: '5-Star Reviews',
    },
    {
      uri: RiskFreeImage,
      alt: 'business insider',
      title: 'risk free',
      description: 'Money Back Guarantee',
    },
  ]

  const changeMobileOrder = (index) => {
    switch (index) {
      case 0:
        return 'order-3'
      case 1:
        return 'order-2'
      case 2:
        return 'order-1'
      case 3:
        return 'order-4'
      default:
    }
  }
</script>

<div class="bg-[#e3e1db] xs:bg-white">
  <Section center="true" verticalPadding="none">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto py-2.5">
      <div class="xs:flex flex-row">
        <div class="flex-1 xs:text-left">
          <div class="flex gap-0 xs:gap-4 flex-wrap xs:flex-nowrap">
            {#each images as item, i}
              <div
                class="flex flex-col xs:flex-row items-center gap-2.5 basis-1/2 xs:basis-full {changeMobileOrder(
                  i,
                )} sm:order-none"
              >
                <div>
                  <img src={item.uri} alt={item.alt} class="w-10 xs:w-[55px]" />
                </div>
                <div>
                  <p class="font-bold uppercase mb-1">{item.title}</p>
                  <p class="text-sm leading-5">{item.description}</p>
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </Section>
</div>
