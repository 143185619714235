<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'fire'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  viewBox="0 0 14 16"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M3.70697 15.979C3.92575 16.0621 4.15653 15.8821 4.02259 15.7332C3.29554 14.9249 2.60707 13.5647 3.70397 11.8129C5.53319 8.89167 6.64627 7.37868 6.64627 7.37868C6.64627 7.37868 7.2383 9.14433 8.84318 10.7142C10.3878 12.2252 11.2332 14.1254 9.87025 15.7085C9.74235 15.857 9.9679 16.0323 10.1852 15.9529C11.8724 15.3364 13.7647 14.0997 13.9782 11.6387C14.0569 10.8867 13.9389 9.8339 13.3485 8.50535C12.5895 6.82153 11.6563 6.03583 11.1164 5.69842C10.9549 5.59748 10.7037 5.68694 10.7169 5.83994C10.8743 7.65677 9.91747 8.11782 9.37286 7.07879C9.15536 6.66378 9.02851 5.94598 9.02851 5.07183C9.02851 3.61654 8.43746 2.11845 7.1344 0.901089C6.79555 0.584513 6.39905 0.288696 5.94414 0.0364351C5.77945 -0.0549113 5.54073 0.0369057 5.5558 0.185455C5.65586 1.17202 5.56521 3.99944 2.0928 7.37732C-1.05565 10.5107 0.164363 12.9171 0.597275 13.5689C1.42497 14.8175 2.57947 15.5507 3.70697 15.979Z"
  />
</svg>
