<script>
  import StockingImg from '@/images/perfectstocking-with-button.jpeg'
  import FreeShippingIconImg from '@/images/fast-shipping-gray.png'
  import DollarIconImg from '@/images/hand-holding-dollar-icon-gray.png'
  import ShieldIconImg from '@/images/shield-icon-gray.png'

  function jumpToBuyBox() {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<div class="max-w-[940px] mx-auto text-center">
  <div class="text-center">
    <div class="w-full md:w-4/5 mx-auto py-[10%] md:py-[5%]">
      <div
        tabindex="-1"
        on:click={() => jumpToBuyBox()}
        on:keypress={() => jumpToBuyBox()}
        role="button"
      >
        <img
          src={StockingImg}
          alt="the perfect stocking stuffer"
          class="rounded-3xl cursor-pointer"
        />
      </div>
    </div>
  </div>

  <div class="flex justify-between flex-row py-[2%]">
    <div class="text-center w-1/3 shrink-0 grow basis-1/3">
      <div class="w-3/5 mx-auto">
        <img
          src={FreeShippingIconImg}
          alt={'FREE shipping'}
          class="w-16 h-16"
        />
      </div>
      <p class="font-semibold mx-auto text-xs leading-tight md:text-15">
        <span class="font-bold">FREE</span> <br />Shipping
      </p>
    </div>
    <div class="text-center w-1/3 shrink-0 grow basis-1/3 md:basis-1/3">
      <div class="w-3/5 mx-auto">
        <img
          src={DollarIconImg}
          alt={'100% Money Guarantee'}
          class="w-16 h-16"
        />
      </div>
      <p class="font-semibold mx-auto text-xs leading-tight md:text-15">
        <span class="font-bold">100% money-</span> <br />back guarantee!
      </p>
    </div>
    <div class="text-center w-1/3 shrink-0 grow basis-1/3">
      <div class="w-3/5 mx-auto">
        <img
          src={ShieldIconImg}
          alt={'Free 1 year warranty'}
          class="w-16 h-16"
        />
      </div>
      <p class="font-semibold mx-auto text-xs leading-tight md:text-15">
        <span class="font-bold">FREE</span> 1-year <br /> warranty
      </p>
    </div>
  </div>
</div>
