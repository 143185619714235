<script>
  import Scanner from '@/images/landing-page/scorpio/premium-clarity/scanner.gif'
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<Section center="true">
  <div class="md:py-10">
    <h2 class="mb-6">FIXD Is The ONLY Car Scanner That Tells You:</h2>

    <div class="mb-7 hidden md:block">
      <img src={Scanner} alt="scanner" />
    </div>

    <div class="text-left max-w-[424px] mx-auto mb-10">
      <ul class="list-disc">
        <li>
          What your check engine light means <span class="font-bold"
            >in plain English</span
          >
        </li>
        <li>
          Your most likely repair and <span class="font-bold"
            >how much it should cost</span
          >
        </li>
        <li>
          How to fix the issue yourself (and a <span class="font-bold"
            >Mechanic Hotline</span
          > to walk you through it)
        </li>
      </ul>
    </div>

    <div class="text-center">
      <h2 class="font-semibold">
        ... for only <span class="text-red strikethrough">$59.99</span>
        <span class="text-green">$19.99!</span>
      </h2>
    </div>

    <slot name="cta" />
  </div>
</Section>
