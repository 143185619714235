<script>
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Banner from '@/components/landing-page/Banner.svelte'
  import BuyBoxV2 from '@/components/landing-page/widgets/BuyBoxV2.svelte'

  import StaticReviews from '@/components/landing-page/widgets/StaticReviews.svelte'

  import CostImage from '@/images/landing-page/scorpio/3-tips-cost.jpg'
  import IncorrectReviewImage from '@/images/landing-page/scorpio/3-tips-incorrect-review.jpg'
  import MechanicImage from '@/images/landing-page/scorpio/3-tips-mechanic.jpg'
  import SensorImage from '@/images/landing-page/scorpio/3-tips-dsc.jpg'
  import DealImage from '@/images/landing-page/scorpio/3-tips-dealad.jpg'
  import ProductImage from '@/images/landing-page/scorpio/3-tips-productimage1.jpg'
  import SensorInstallImage from '@/images/landing-page/scorpio/3-tips-sensorinstall.jpeg'
  import FAQ from '@/components/landing-page/FAQ.svelte'

  export let funnel
  export let gateway

  const scrollToBuyBox = () => {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<main class="text-black relative">
  <div class="h-14 md:h-[90px]">
    <div class="fixed top-0 bg-white w-full z-20 shadow-lg">
      <div class="py-3.5">
        <Banner darkTheme={false} height="short" />
      </div>
    </div>
  </div>

  <Section verticalPadding="short">
    <header class="text-center">
      <h1 class="text-4xl md:text-54">
        3 Tips Mechanics Don't Want You To Know!
      </h1>
    </header>
    <div class="space-y-3">
      <article class="space-y-6">
        <img src={CostImage} alt="cost" />
        <p class="text-base md:text-lg">
          Auto repairs are expensive and take up your valuable time! <span
            class="font-bold"
            >AND how do you know if you are being taken advantage of?</span
          >
        </p>
        <p class="text-base md:text-lg">
          Well according to a scientific study <span class="font-bold">
            there are 3 ways (and 1 product) to ensure you'll never overpay
            again.</span
          >
        </p>
      </article>

      <article class="space-y-6">
        <div>
          <h2 class="mb-3">
            <span class="text-green">1.</span> Being confident and knowing the problem
            reduces the price.
          </h2>
          <img src={IncorrectReviewImage} alt="incorrect review" />
        </div>
        <p class="text-base md:text-lg">
          <span class="font-bold">
            The best way to combat the increase in price</span
          > is to come in with a clear understanding of your car problem.
        </p>
      </article>

      <article class="space-y-6">
        <div>
          <h2 class="mb-3">
            <span class="text-green">2.</span> Mechanics take advantage of you more
            when your information is incorrect.
          </h2>
          <img src={MechanicImage} alt="mechanic" />
        </div>
        <p class="text-base md:text-lg">
          To save your hard-earned cash, <span class="font-bold">
            you have to be DETAILED and ACCURATE with information.</span
          >
        </p>
      </article>

      <article class="space-y-6">
        <div>
          <h2 class="mb-3">
            <span class="text-green">3.</span> Buy a tool that can show you the problem
            & help you fix it, too!
          </h2>
          <img src={SensorImage} alt="trusted sensor" />
        </div>
        <p class="text-base md:text-lg">
          <span class="font-bold">
            You can feel confident about your repair</span
          > when you know the issue before going to the mechanic.
        </p>
      </article>
    </div>

    <article>
      <div class="md:px-2.5 my-2.5">
        <h2 class="text-30">
          What if I told you mechanics don’t actually know what’s wrong with
          your car?
        </h2>
      </div>
      <div class="pt-5 pb-4">
        <p class="text-base md:text-lg">It's true.</p>
      </div>
      <h2 class="text-30 px-2.5">
        Every car after 1996 has a computer in their car.
      </h2>
      <div class="py-5 text-base md:text-lg">
        <p>THIS IS THE TRICK MECHANICS DON’T WANT YOU TO KNOW.</p>
      </div>
      <p class="text-base md:text-lg">
        <span class="font-bold">
          Your car computer can tell you what's wrong when the check engine
          light comes on.</span
        >
        All you need is a tool that will talk with it.
        <span class="font-bold"
          >This is where a brand new invention called FIXD comes into play.</span
        >
      </p>
    </article>

    <article class="space-y-6 mt-4">
      <div>
        <img src={DealImage} alt="sensor plugged" />
        <h2 class="mt-2.5">
          FIXD is the tool that can talk to your car's computer.
        </h2>
      </div>
      <p class="text-base md:text-lg">
        FIXD was developed by three engineers from Georgia Tech. They designed
        <button class="bare inline" on:click={scrollToBuyBox}
          ><span class="underline">the FIXD app</span></button
        > to give every driver access to the transparent and easy-to-understand information
        they need to fix and maintain their vehicle - without breaking the bank.
      </p>
      <p class="text-base md:text-lg">
        With the FIXD Sensor and free app, you can see what your check engine
        light means before going to the mechanic and feel confident about
        repairs.
      </p>
    </article>

    <article class="mt-5">
      <h2 class="text-30 md:px-2.5">Wondering if your car is compatible?</h2>
      <div class="py-5">
        <p class="text-base md:text-lg font-bold">
          If your car is made after 1996 it is!
        </p>
      </div>
      <div class="pb-5">
        <p class="text-base md:text-lg">
          We were like you. <span class="font-bold"
            >The solution COULD NOT be this easy...</span
          > Then we started seeing FIXD in the news.
        </p>
      </div>
    </article>

    <article>
      <div class="relative pb-[56.25%] w-full h-[0px]">
        <iframe
          class="absolute top-0 left-0 w-full h-full"
          src="https://www.youtube.com/embed/Ddq2HLi_ZyE?si=JahhI91o1xea3opC"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        />
      </div>
      <h2 class="mt-2.5">
        FIXD is shaking up the automotive industry with over 2 million users and
        counting.
      </h2>
      <div class="py-5">
        <p class="text-base md:text-lg">
          They believe knowledge is power. The creators of <button
            class="bare"
            on:click={scrollToBuyBox}
            ><span class="underline">FIXD</span></button
          > want to empower drivers everywhere to save money and feel confident about
          car care.
        </p>
      </div>
    </article>

    <article>
      <div>
        <img src={ProductImage} alt="sensor two" />
      </div>
      <h2 class="py-2.5">
        The sensors mechanics use are bulky and cost over $1000.
      </h2>
      <div class="pt-5 pb-4">
        <p class="text-base md:text-lg">
          That's why FIXD is such a GENIUS product.
        </p>
      </div>
      <p class="text-base md:text-lg">
        The FIXD sensor is tiny because it <span class="font-bold"
          >uses your smartphone to translate the check engine light into simple
          terms.</span
        >
      </p>
    </article>

    <article class="mt-5">
      <h2>
        The FREE FIXD app on your smartphone connects to the FIXD sensor through
        Bluetooth.
      </h2>
      <div class="pt-5 pb-4">
        <p class="text-base md:text-lg">
          The FIXD sensor uses the same technology that connects your phone to
          your headphones!
        </p>
      </div>
      <div class="space-y-5">
        <h2>Do I Need a Subscription?</h2>

        <p class="text-base md:text-lg">
          The great thing about the FIXD Sensor is that it comes with a free app
          for both iPhone and Android smartphones. No subscription is required
          to read the check engine codes on your car, see how serious they are,
          and get automated maintenance alerts.
        </p>
        <p class="text-base md:text-lg">
          BUT… if you want to take it a step further with cost estimates for
          repairs, the most likely causes of your check engine light and how to
          fix it, access to the Mechanic Hotline, and more, there’s an optional
          FIXD Premium subscription for a low annual fee.
        </p>

        <h2>FIXD works with YOUR car!</h2>
        <p class="text-base md:text-lg">
          Every gas-powered vehicle made in 1996 or newer will work with FIXD.
        </p>
      </div>
    </article>

    <article class="mt-5 space-y-6">
      <img src={SensorInstallImage} alt="sensor install" />
      <h2>
        FIXD is based in Atlanta, Georgia and proud to be an American company.
      </h2>
      <p class="text-base md:text-lg">
        They have a 100% satisfaction guarantee and offer free shipping.
      </p>
      <p class="text-base md:text-lg">
        Usually, the FIXD Sensor goes for $59 but...
      </p>
      <p class="text-base md:text-lg">
        For a limited time, FIXD is having a special deal! Get your very own
        sensor for only $19.99 (that's 67% off)!!
      </p>
    </article>
  </Section>

  <Section center="true" verticalPadding="short" horizontalPadding="none">
    <div class="bg-white py-[5%] rounded-3xl">
      <div class="max-w-[325px] md:max-w-screen-md mx-auto">
        <BuyBoxV2 {funnel} {gateway} />
      </div>
    </div>
  </Section>

  <FAQ />

  <div class="max-w-[940px] mx-auto">
    <div class="text-center my-2">
      <h2 class="px-5 md:px-0 text-2xl md:text-36 font-medium">
        Over 50,000 Sold Every Month! Check Out What People Are Saying:
      </h2>
    </div>
    <StaticReviews />
  </div>

  <Footer />
  <div class="fixed left-0 right-0 bottom-4 w-11/12 mx-auto xs:hidden">
    <div class="w-full shadow-lg rounded-full">
      <button class="rounded-button" on:click={scrollToBuyBox}>
        <span class="text-15">Claim Offer Only $19.99!</span>
      </button>
    </div>
  </div>
</main>
