<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import CompatibilityWidget from '@/components/landing-page/webflow/CompatibilityWidget.svelte'

  function jumpToBuyBox() {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<style>
  .custom-button {
    border-radius: 20px;
    padding: 9px 15px;
    font-size: 15px;
    color: #333;
  }

  @media only screen and (width >= 768px) {
    .custom-button {
      color: #fff;
    }
  }
</style>

<Section center="true">
  <slot name="header">
    <div class="mt-6">
      <h1 class="text-lg xs:text-34 font-bold xs:font-medium mb-2">
        Wondering If Your Car Is Compatible?
      </h1>
    </div>
  </slot>

  <slot name="content" />

  <CompatibilityWidget>
    <div slot="compatible" class="mt-1">
      <button class="custom-button uppercase" on:click={jumpToBuyBox}>
        buy FIXD!
      </button>
    </div>
  </CompatibilityWidget>
</Section>
